import React, { useState } from 'react'

import { useLocation } from '@reach/router'

import Link from 'src/components/link/index'
import Dialog from './dialog'

import styles from './index.module.scss'

const Footer = () => {
	const { pathname } = useLocation()

	const [open, setOpen] = useState(false)
	const [address, setAddress] = useState(``)

	const openDialog = (address) => {
		setOpen(true)
		setAddress(address)
	}

	return (
		<footer className={styles.footer}>
			<div className={styles.wrapper}>
				<div className={styles.logo}>
					<Link to={pathname === '/' ? '/#' : '/'}>WE ARE THE FIT</Link>
				</div>

				<ul className={styles.sns}>
					<li>
						<Link to="https://www.facebook.com/wearethefit/">facebook</Link>
					</li>
					<li>
						<Link to="https://twitter.com/WEARETHEFIT24">twitter</Link>
					</li>
					<li>
						<Link to="https://www.instagram.com/wearethefit_24/">instagram</Link>
					</li>
				</ul>

				<div className={styles.message}>
					We are not just a gym. We want to be a place to create a purpose for your life to move your body.
				</div>

				<div className={styles.gyms}>
					<dl>
						<dt>
							<Link to="/gyms/toritsudai/">
								WE ARE THE FIT <br />
								TORITSUDAI
							</Link>
						</dt>
						<dd>
							<div className={styles.address}>
								〒152-0031 <br />
								東京都目黒区中根1丁目3-11
							</div>
							<div className={styles.tel}>TEL：03-5726-8065</div>
							<button onClick={() => openDialog(`info@thefit.jp`)}>お問い合わせはこちら</button>
						</dd>
					</dl>
					<dl>
						<dt>
							<Link to="/gyms/ekoda/">
								WE ARE THE FIT <br />
								EKODA
							</Link>
						</dt>
						<dd>
							<div className={styles.address}>
								〒176-0005 <br />
								東京都練馬区旭丘1-71-6 プロパティ江古田3F
							</div>
							<div className={styles.tel}>TEL：03-5906-5777</div>
							<button onClick={() => openDialog(`ekoda@thefit.jp`)}>お問い合わせはこちら</button>
						</dd>
					</dl>
				</div>

				<ul className={styles.nav1}>
					<li>
						<Link to={pathname === '/' ? '/#news' : '/news/'}>NEWS</Link>
					</li>
					<li>
						<Link to="/#about">ABOUT</Link>
					</li>
					<li>
						<Link to="/#gyms">GYMS</Link>
					</li>
					<li>
						<Link to="/#price">PRICE</Link>
					</li>
					<li>
						<Link to="/faq/">FAQ</Link>
					</li>
					<li>
						<Link to="/beginners/">FOR BEGINNER</Link>
					</li>
					<li>
						<Link to="/iatf/">I AM THE FIT</Link>
					</li>
					<li>
						<Link to="https://body-s.jp/ekoda/">Body Styling</Link>
					</li>
				</ul>

				<ul className={styles.nav2}>
					<li>
						<Link to="/company/">会社概要</Link>
					</li>
					<li>
						<Link to="/agreement/">会員規則</Link>
					</li>
					<li>
						<Link to="/privacy/">プライバシーポリシー</Link>
					</li>
					<li>
						<Link to="/law/">特定商取引法に基づく表記</Link>
					</li>
				</ul>

				<div className={styles.copyright}>
					<span>© 2020 WE ARE THE FIT. All Rights Reserved</span>
				</div>
			</div>

			<Dialog isOpen={open} close={() => setOpen(false)} address={address} />
		</footer>
	)
}

export default Footer
